import React, { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import axios from 'axios';
import Icon from '@atoms/Icon/Icon';
import useCustomer from '@hooks/useCustomer';
import useCustomRouter from '@hooks/useCustomRouter';
import KEYS from '@helpers/keys';
import paths, { LoginQueryParams } from '@constants/paths';
import LostPassword from '@organisms/LostPassword/LostPassword';
import LoginHint from './LoginHint';
import MyAccount from '@icons/my-account.svg';

import { StyledLoginLink, StyledLoginText } from './Login.styles';
import useResponsive from '@hooks/useResponsive';
import LoginModalWithBankId from '@organisms/LoginModalWithBankId/LoginModalWithBankId';
import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';

type FromType = 'MY_LISTS' | 'CART' | 'MY_COMMON_ITEMS' | undefined;

const LoginHintInitializer = ({
  openModal,
}: {
  openModal: (_?: any, loginHintCloseCallback?: VoidFunction) => void;
}) => {
  const router = useCustomRouter();
  const [showLoginHint, setShowLoginHint] = useState(false);
  const { isMobile } = useResponsive();

  useEffect(() => {
    if (router.asPath.indexOf(paths.OFFERS) > -1) {
      const sessionShowLoginHint = JSON.parse(sessionStorage.getItem('willys.showLoginHint') || '{}');
      if (sessionShowLoginHint !== false) {
        setShowLoginHint(true);
        sessionStorage.setItem('willys.showLoginHint', 'false'); // show only once
      }
    }
  }, [router.asPath]);

  const handleLoginHintClose = () => {
    setShowLoginHint(false);
    sessionStorage.setItem('willys.showLoginHint', 'false');
  };

  const handleClickLogin = () => {
    handleLoginHintClose();
    openModal(undefined, handleLoginHintClose);
  };

  const handleClickRegister = () => {
    handleLoginHintClose();
    if (router.asPath.indexOf(paths.REGISTER_PRIVATE) === -1) {
      router.push({
        pathname: paths.REGISTER_PRIVATE_CREATE,
      });
    }
  };

  return !isMobile && showLoginHint ? (
    <LoginHint
      onClickClose={handleLoginHintClose}
      onClickLogin={handleClickLogin}
      onClickRegister={handleClickRegister}
    />
  ) : null;
};

export interface Props {
  isCheckout: boolean;
}

const Login = ({ isCheckout }: Props) => {
  const { t } = useTranslation('common');
  const router = useCustomRouter();
  const { customer, isValidating, isLoading } = useCustomer();

  const [modalVisibility, setModalVisibility] = useState(false);
  const [showLostPassword, setShowLostPassword] = useState(false);

  const source = axios.CancelToken.source();
  const { from, name, tab: currentTab, productCode, login, loginConfirm, ...query } = router.query;
  const isLoginConfirm = loginConfirm === 'true';

  const { fromDesktop } = useResponsive();

  const closeModal = (success = false) => {
    const { pathname } = router;
    const { username, tab, ...q } = query;
    pathname.replace(/(\/erbjudanden).*$/, '$1');
    router.push(
      {
        pathname,
        query: {
          ...q,
          ...(success ? { from: LoginQueryParams.LOGIN_CONFIRM_SUCCESS } : {}),
        },
      },
      { pathname },
      { shallow: false }
    );
    setModalVisibility(false);
  };

  const getBasePath = () => {
    return router.asPath.split('?')[0];
  };

  const openModal = (e?: React.MouseEvent, loginHintCloseCallback?: VoidFunction) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (!modalVisibility) {
      if (getBasePath() !== paths.USER_LOGIN || getBasePath() !== paths.B2B_LOGIN) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              ...(isCheckout ? { from: LoginQueryParams.CHECKOUT_PAGE } : {}),
            },
          },
          paths.USER_LOGIN,
          { shallow: true }
        );
      }
      setModalVisibility(true);
      if (loginHintCloseCallback) loginHintCloseCallback();
    }
  };

  const closeLostPassword = () => {
    setShowLostPassword(false);
    const { username, tab, ...queryWithoutTab } = router.query;
    router.replace({ pathname: router.pathname, query: queryWithoutTab }, router.pathname, { shallow: true });
  };

  useEffect(() => {
    // Make sure customer is loaded
    if (customer && !isLoading) {
      if (getBasePath() === paths.USER_LOGIN || getBasePath() === paths.B2B_LOGIN) {
        if (customer?.isAnonymous || isLoginConfirm) {
          openModal();
          setShowLostPassword(false);
        } else {
          router.replace(router.pathname, router.pathname, { shallow: true });
        }
      } else if (
        getBasePath().startsWith(paths.LOST_PASSWORD) ||
        getBasePath().startsWith(paths.LOST_PASSWORD_B2B) ||
        getBasePath().startsWith(paths.RESET_PASSWORD) ||
        getBasePath().startsWith(paths.RESET_PASSWORD_B2B)
      ) {
        if (customer?.isAnonymous) {
          setShowLostPassword(true);
          setModalVisibility(false);
        } else {
          router.replace({ pathname: paths.MY_ACCOUNT });
        }
      } else if (router.asPath.indexOf(paths.OFFERS) > -1) {
        setShowLostPassword(false);
        setModalVisibility(false);
      } else if (modalVisibility) {
        setModalVisibility(false);
      } else if (showLostPassword) {
        setShowLostPassword(false);
      }

      return () => {
        source.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, customer, isLoading]);

  useEffect(() => {
    if (!customer?.isAnonymous) {
      setModalVisibility(false);
    }
  }, [customer?.isAnonymous]);

  return (
    <>
      {customer?.isAnonymous && !isValidating && (
        <>
          {fromDesktop && (
            <StyledLoginLink
              href={paths.REGISTER_B2C_IDENTIFY}
              onClick={() => trackLinkNavigation('header', t('navigation->createAccount'))}
            >
              <StyledLoginText>{t('navigation->createAccount')}</StyledLoginText>
            </StyledLoginLink>
          )}

          <StyledLoginLink
            href="#"
            onClick={openModal}
            data-testid="open-login-modal"
            isLastElement
            aria-label={t('loginLabel')}
            aria-live="polite"
          >
            <Icon svg={MyAccount} size={20} />
            {fromDesktop && <StyledLoginText hasIcon>{t('navigation->logIn')}</StyledLoginText>}
          </StyledLoginLink>

          <LoginHintInitializer openModal={openModal} />
        </>
      )}

      {modalVisibility && (
        <LoginModalWithBankId
          onClose={closeModal}
          isLoginConfirm={isLoginConfirm}
          isCheckout={isCheckout}
          from={from as FromType}
          tab={currentTab as 'b2c' | 'b2b'}
        />
      )}

      {showLostPassword && <LostPassword onCloseHandler={closeLostPassword} tab={currentTab as 'b2c' | 'b2b'} />}
    </>
  );
};

export default Login;
