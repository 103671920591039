import { ChangeEvent, FocusEvent, Key } from 'react';
import useTranslation from 'next-translate/useTranslation';
import InputTooltip from '@organisms/InputTooltip/InputTooltip';
import InputField from '@atoms/InputField/InputField';
import { loginRegex } from '@helpers/validationRegex';
import {
  StyledBackendError,
  StyledCheckbox,
  StyledErrorText,
  StyledFormBottom,
  StyledInputActionsWrapper,
  StyledInputsItem,
  StyledInputsWrapper,
} from './LoginForm.styles';
import paths from '@constants/paths';
import Link from '@atoms/Link/Link';
import Text from '@atoms/Text/Text';
import useCustomRouter from '@hooks/useCustomRouter';
import Info from '@icons/info.svg';
import Button from '@atoms/Button/Button';
import Trans from 'next-translate/Trans';

interface LoginFormProps {
  activeTab: 'b2b' | 'b2c';
  formValues: {
    b2b: {
      j_username: string;
      j_username_key: string;
      j_password: string;
      j_password_key: string;
    };
    b2c: {
      j_username: string;
      j_username_key: string;
      j_password: string;
      j_password_key: string;
    };
    j_remember_me: boolean;
  };
  globalErrorMessage: { b2b: string; b2c: string };
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void;
  currentLoginMethod: 'b2b' | 'b2c';
  isLoginConfirm: boolean;
  handleCheckboxChange: (checked: boolean) => void;
  onClose: (success?: boolean) => void;
  isSubmitting: boolean;
  onTabChange: (x: Key) => void | undefined;
}

const LoginForm = ({
  activeTab,
  formValues,
  globalErrorMessage,
  handleInputChange,
  handleBlur,
  currentLoginMethod,
  isLoginConfirm,
  handleCheckboxChange,
  onClose,
  isSubmitting,
  onTabChange,
}: LoginFormProps) => {
  const { t } = useTranslation('login');
  const router = useCustomRouter();
  const placeholder =
    activeTab === 'b2b' ? t('login->inputs->company->placeholder') : t('login->inputs->ssn->placeholder');
  const tooltip = activeTab === 'b2b' ? t('login->inputs->company->tooltip') : t('login->inputs->ssn->tooltip');
  const password = activeTab === 'b2b' ? formValues.b2b.j_password : formValues.b2c.j_password;
  const value = activeTab === 'b2b' ? formValues.b2b.j_username : formValues.b2c.j_username;
  return (
    <StyledInputsWrapper>
      {globalErrorMessage.b2c && (
        <StyledBackendError data-testid="backend-error">
          <StyledErrorText>{t(globalErrorMessage.b2c)}</StyledErrorText>
        </StyledBackendError>
      )}
      {globalErrorMessage.b2b && (
        <StyledBackendError data-testid="backend-error">
          <StyledErrorText>{t(globalErrorMessage.b2b)}</StyledErrorText>
        </StyledBackendError>
      )}
      <StyledInputsItem>
        <InputTooltip
          tooltipAlign="right"
          inputName="j_username"
          inputAriaLabel={placeholder}
          inputValue={value}
          inputType="text"
          inputaria-label={placeholder}
          inputMaxLength={activeTab === 'b2b' ? 6 : undefined}
          inputOnChange={handleInputChange}
          inputOnBlur={handleBlur}
          iconProps={{ svg: Info, size: 16 }}
          placeholder={placeholder}
          tooltipContent={<>{tooltip}</>}
          pattern="[0-9]*"
          customValidator={(val) => {
            if (!loginRegex[currentLoginMethod].test(val)) {
              return (
                <Trans
                  i18nKey={`login:login->error->${currentLoginMethod}`}
                  components={[
                    <Link
                      href={paths.B2B_LOGIN}
                      onClick={(e) => {
                        e.preventDefault();
                        onTabChange('b2b');
                      }}
                      key={0}
                    >
                      {}
                    </Link>,
                  ]}
                />
              );
            }
            return '';
          }}
          required
          testId={`login-input-username-${currentLoginMethod}`}
        />
      </StyledInputsItem>
      <StyledInputsItem>
        <InputField
          name="j_password"
          value={password}
          type="password"
          onChange={handleInputChange}
          aria-label={t('login->inputs->password->placeholder')}
          placeholder={t('login->inputs->password->placeholder')}
          autoComplete={isLoginConfirm ? 'one-time-code' : undefined}
          required
          testId={`login-input-password-${currentLoginMethod}`}
          passwordVisibilityToggle
        />
      </StyledInputsItem>

      <StyledFormBottom>
        {!isLoginConfirm && (
          <StyledInputActionsWrapper>
            <StyledCheckbox id="login-remember-me" checked={formValues.j_remember_me} onChange={handleCheckboxChange}>
              {t('login->rememberMe')}
            </StyledCheckbox>

            <Text type="body" size="small">
              <Link
                variant="primary"
                href={{
                  pathname: router.pathname,
                  query: { ...router.query, username: formValues[activeTab].j_username, tab: activeTab },
                }}
                asProp={activeTab === 'b2c' ? paths.LOST_PASSWORD : paths.LOST_PASSWORD_B2B}
                internal
              >
                {t('login->links->resetPassword')}
              </Link>
            </Text>
          </StyledInputActionsWrapper>
        )}

        {isLoginConfirm && (
          <Button theme="secondary" onClick={() => onClose()}>
            {t('common:button->cancel')}
          </Button>
        )}

        <Button type="submit" isSubmitting={isSubmitting} data-testid="login-button-submit">
          {t('login->submit')}
        </Button>
      </StyledFormBottom>
    </StyledInputsWrapper>
  );
};

export default LoginForm;
