import React, { ChangeEvent, FocusEvent, ReactElement } from 'react';
import InputField from '@atoms/InputField/InputField';
import { StyledInputTooltipContainer, StyledIconTooltip } from './InputTooltip.styles';
import { IconProps } from '@atoms/Icon/Icon';

type VariantType = 'box' | 'line' | 'rounded';
type AlignType = 'left' | 'center' | 'right';
type PositionType = 'top' | 'bottom';

interface Props {
  inputName: string;
  inputType: InputFieldType;
  inputValue?: string;
  inputLabel?: string;
  inputAriaLabel: string;
  inputMaxLength?: number;
  inputOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputOnBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  iconProps: IconProps;
  variant?: VariantType;
  placeholder?: string;
  tooltipContent: string | ReactElement;
  tooltipAlign?: AlignType;
  tooltipPosition?: PositionType;
  pattern?: string;
  required?: boolean;
  customValidity?: string;
  customValidator?: (value: string) => React.ReactNode | string;
  testId?: string;
}

const InputTooltip = ({
  inputLabel,
  inputName,
  inputType,
  inputValue,
  inputAriaLabel,
  inputMaxLength,
  inputOnChange,
  inputOnBlur,
  iconProps,
  variant,
  placeholder,
  tooltipContent,
  pattern,
  tooltipAlign = 'center',
  tooltipPosition = 'bottom',
  required,
  customValidity,
  customValidator,
  testId,
}: Props) => {
  return (
    <StyledInputTooltipContainer>
      <InputField
        label={inputLabel}
        name={inputName}
        type={inputType}
        variant={variant}
        placeholder={placeholder}
        value={inputValue}
        maxLength={inputMaxLength}
        onChange={inputOnChange}
        onBlur={inputOnBlur}
        aria-label={inputAriaLabel}
        pattern={pattern}
        required={required}
        customValidity={customValidity}
        customValidator={customValidator}
        testId={testId}
      />
      <StyledIconTooltip
        content={tooltipContent}
        iconProps={iconProps}
        align={tooltipAlign}
        position={tooltipPosition}
        color="black"
        withLabel={!!inputLabel}
      />
    </StyledInputTooltipContainer>
  );
};

export default InputTooltip;
