import {
  StyledContentWrapper,
  StyledFooter,
  StyledFooterButton,
  StyledFooterListItem,
  StyledForm,
  StyledFromMessage,
  StyledTabsWrapper,
  StyledLoginModal,
} from '@organisms/LoginModalWithBankId/LoginModalWithBankId.styles';
import Heading from '@atoms/Heading/Heading';
import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import Tabs from '@molecules/Tabs/Tabs';
import TabContent from '@molecules/Tabs/TabContent';
import useLoginModal from '@organisms/LoginModalWithBankId/useLoginModal';
import LoginForm from '@organisms/LoginModalWithBankId/LoginForm/LoginForm';
import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import { useEffect, useMemo } from 'react';
import { trackLoginConfirm } from '@helpers/analyticsHelpers/trackLogin';
import useCustomer from '@hooks/useCustomer';
import BankIdLoginFlow from '@organisms/BankIdLoginFlow/BankIdLoginFlow';
import useCustomRouter from '@hooks/useCustomRouter';
import useLocalStorage from '@hooks/useLocalStorage';
import config from '@config';

export interface LoginModalWithBankIdProps {
  onClose: (success?: boolean) => void;
  from:
    | 'MY_LISTS'
    | 'CART'
    | 'CART_PAGE'
    | 'MY_COMMON_ITEMS'
    | 'MY_ACCOUNT'
    | 'CREATE_ACCOUNT'
    | 'ACCOUNT_ORDERS'
    | 'CHECKOUT_PAGE'
    | undefined;
  tab?: 'b2b' | 'b2c' | 'bankId';
  isLoginConfirm: boolean;
  isCheckout: boolean;
}

const tabToMode = (tab: LoginModalWithBankIdProps['tab']) => (tab === 'bankId' ? 'b2c' : tab);

const LoginModalWithBankId = ({ onClose, from, isLoginConfirm, isCheckout, tab }: LoginModalWithBankIdProps) => {
  const router = useCustomRouter();
  const { t } = useTranslation('login');
  const mode = useMemo(() => tabToMode(tab) || 'b2c', [tab]);
  const { customer } = useCustomer();
  const {
    isSubmitting,
    activeTab,
    activeTabWithoutBankId,
    formValues,
    globalErrorMessage,
    handleInputChange,
    onTabChange,
    handleBlur,
    onSubmitHandler,
    handleCheckboxChange,
    onLoginSuccess,
  } = useLoginModal({
    onClose,
    tab: mode,
  });
  const [bankIdTabLastSelected] = useLocalStorage(config.LOCAL_STORAGE_SELECTORS.bankIdTabLastSelected, false);

  const getBasePath = () => {
    return router.asPath.split('?')[0];
  };

  useEffect(() => {
    if (router.query.tab === 'bankId' || bankIdTabLastSelected) {
      onTabChange('bankId', true);
    } else if (getBasePath() === paths.B2B_LOGIN || (isLoginConfirm && customer?.isB2BCustomer)) {
      onTabChange('b2b', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerLink = {
    b2c: paths.REGISTER_B2C_IDENTIFY,
    b2b: paths.REGISTER_B2B_IDENTIFY,
  };

  const fromQueryMessage = () => {
    const message = `login->fromMessage->${from}`;
    if (!from || !t(message) || t(message) === message) {
      return null;
    }

    return (
      <StyledFromMessage align="center" type="body" data-testid="login-backend-message">
        {t(message)}
      </StyledFromMessage>
    );
  };

  useEffect(() => {
    if (isLoginConfirm) {
      trackLoginConfirm(customer?.isB2BCustomer || false, isCheckout);
    }
  }, [isLoginConfirm, customer?.isB2BCustomer, isCheckout]);

  const SwitchLoginModeLink = () => {
    if (activeTab === 'b2b') {
      return (
        <StyledFooterButton
          href={paths.USER_LOGIN}
          onClick={(e) => {
            e.preventDefault();
            onTabChange('b2c');
          }}
        >
          {t('login->links->b2cLogin')}
        </StyledFooterButton>
      );
    } else {
      return (
        <StyledFooterButton
          href={paths.B2B_LOGIN}
          onClick={(e) => {
            e.preventDefault();
            onTabChange('b2b');
          }}
        >
          {t('login->links->b2bLogin')}
        </StyledFooterButton>
      );
    }
  };

  const tabContent = () => (
    <LoginForm
      isLoginConfirm={isLoginConfirm}
      key={activeTab}
      activeTab={activeTabWithoutBankId}
      formValues={formValues}
      globalErrorMessage={globalErrorMessage}
      handleInputChange={handleInputChange}
      handleBlur={handleBlur}
      currentLoginMethod={activeTabWithoutBankId}
      handleCheckboxChange={handleCheckboxChange}
      onClose={onClose}
      isSubmitting={isSubmitting}
      onTabChange={onTabChange}
    />
  );

  return (
    <StyledLoginModal size="medium" hasCloseIcon onClose={() => onClose()}>
      <StyledContentWrapper>
        <Heading variant="h2" size="small">
          {activeTab === 'b2b' ? t('login->headingB2B') : t('login->heading')}
        </Heading>

        <StyledForm onSubmit={onSubmitHandler}>
          <StyledTabsWrapper>
            {isLoginConfirm && <Text>{t('login->loginConfirmSubtitle')}</Text>}

            {fromQueryMessage()}

            {isLoginConfirm && tabContent()}

            {!isLoginConfirm &&
              (activeTab === 'b2b' ? (
                tabContent()
              ) : (
                <Tabs activeTab={activeTab} onTabChange={onTabChange}>
                  <TabContent key="b2c" title={t('login->methodTabs->b2cPassword')}>
                    {tabContent()}
                  </TabContent>

                  <TabContent key="bankId" title={t('login->methodTabs->bankId')}>
                    <BankIdLoginFlow
                      closeParentModal={onClose}
                      onLoginSuccess={onLoginSuccess}
                      handleCheckBoxChange={handleCheckboxChange}
                      rememberMe={formValues?.j_remember_me}
                    />
                  </TabContent>
                </Tabs>
              ))}
          </StyledTabsWrapper>
        </StyledForm>

        <StyledFooter data-testid="login-modal-footer">
          {from === 'CART_PAGE' ? (
            <ul>
              <StyledFooterListItem>
                <Link variant="primary" href={paths.CHECKOUT}>
                  {t('login->links->continueAsGuest')}
                </Link>
              </StyledFooterListItem>
              <StyledFooterListItem>
                <SwitchLoginModeLink />

                {t('login->or')}

                <Link
                  variant="primary"
                  href={{
                    pathname: registerLink[mode],
                    query: { username: formValues[mode]?.j_username, tab: mode },
                  }}
                  asProp={registerLink[mode]}
                  internal
                >
                  {t('login->links->createAccount')}
                </Link>
              </StyledFooterListItem>
            </ul>
          ) : (
            <ul>
              <StyledFooterListItem>
                <Link
                  variant="primary"
                  href={{
                    pathname: registerLink[mode],
                    query: { username: formValues[mode]?.j_username, tab: mode },
                  }}
                  asProp={registerLink[mode]}
                  internal
                >
                  {t('login->links->createAccount')}
                </Link>
              </StyledFooterListItem>

              <StyledFooterListItem>
                <SwitchLoginModeLink />
              </StyledFooterListItem>
            </ul>
          )}
        </StyledFooter>
      </StyledContentWrapper>
    </StyledLoginModal>
  );
};

export default LoginModalWithBankId;
